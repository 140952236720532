import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from "../Reducers/authReducer";
import { inApi } from '../services/inquiries';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [inApi.reducerPath]: inApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(inApi.middleware)
})

setupListeners(store.dispatch)
