import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import './index.scss';
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

function EN() {
  const { t } = useTranslation()
  const { languageCode } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     setAlready(false)
  //   }, 60000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  // function getujjj() {
  //   return (
  //     <video autoplay muted loop width="600">
  //       <source src="https://jovia.my.canva.site/videos/7ee74b9edf5f59d2b7b5653d4c3ea10c.mp4" type="video/mp4">
  //         Your browser does not support the video tag.
  //     </video>
  //   )
  // }
  const [isAvailable, setIsAvailable] = React.useState(false);
  const handleReady = () => {
    setIsAvailable(true);
  };


  return (
    <div className="home-page-section">
      <div className='container'>
        <br /><br />
        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', lineHeight: 1.3 }}>
                <strong>JOVIA</strong> - Where gifts become unforgettable experiences</div>
              <p className='font-en-text' style={{ fontSize: '1.4rem', marginBottom: '40px' }}>
                At JOVIA, we transform ordinary
                gifts into extraordinary memories.
                Say goodbye to predictable
                presents and hello to curated
                experiences designed to leave a
                lasting impression.
              </p>
              <div className='hp-app-icons-section'>
                <a href='https://play.google.com/store/apps/details?id=com.amin.jovia' target='_blank'>
                  <div className='hp-app-icon-card'>
                    <img src='/android-icon.svg' />
                  </div>
                </a>
                <div>&nbsp;&nbsp;</div>

                <a href='https://apps.apple.com/ca/app/jovia-%D8%AC%D9%88%D9%81%D9%8A%D8%A7/id6504813556' target='_blank'>
                  <div className='hp-app-icon-card'>
                    <img src='/apple-icon.svg' />
                  </div>
                </a>

              </div>
            </div>
          </div>
          <div className='col-md-6 m-m-top' style={{ textAlign: 'center' }}>
            <img src='/hp-box.png' style={{ width: '100%', position: 'relative', top: '-50px' }} />
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem', marginBottom: '40px', fontWeight: 600, lineHeight: 1.3 }}>
                Experience the Art of Gifting</div>
              <div style={{ marginBottom: '40px', }}>
                <p className='font-en-text' style={{ fontSize: '1.4rem' }}>Traditional vouchers lack the magic of a true
                  gift. JOVIA elevates gifting with our signature
                  unboxing experience, turning every moment
                  into a celebration.</p>
                <p className='font-en-text' style={{ fontSize: '1.4rem' }}>Picture the anticipation as the box opens,
                  releasing delicate butterflies to reveal an
                  exquisitely designed experience voucher. It’s
                  more than a gift—it’s a moment of pure
                  delight.</p>
              </div>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              {isAvailable && <img className='hp-video-frame' src='/mobile-frame.png' />}
              <ReactPlayer
                className='hp-video'
                url={!isMobcreen ?
                  '/jovia-video.mp4' :
                  '/jovia-video.mp4'}
                playing={true}
                controls={false}
                loop={true}
                muted={true}
                playsinline={true}
                onReady={handleReady}
              />

            </div>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>
                A Gift for Every Occasion</div>
              <p className='font-en-text' style={{ fontSize: '1.8rem', fontWeight: 300 }}>Explore our thoughtfully curated collection of experiences tailored for
                every special moment.</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-00.jpg' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px' }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>Pampering Experiences</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Indulge your loved ones with serene spa days
                and the ultimate relaxation.</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-01.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>Adventure Experiences</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Ignite their sense of adventure with thrilling
                experiences, from land to sea.</p>
            </div>
          </div>
          <div className='col-md-4' style={{ textAlign: 'center', padding: '50px' }}>
            <div style={{ background: '#fff', borderTopRightRadius: '80px', borderBottomLeftRadius: '80px', padding: '20px' }}>
              <img src='/jovia-02.png' style={{ borderTopRightRadius: '80px', objectFit: 'cover', width: '100%', height: '300.57937428px', }} />
              <div className='font-en-heading' style={{ padding: '10px', fontSize: '1.2rem', textTransform: 'uppercase' }}><strong>Unique Experiences</strong></div>
              <p className='font-en-text' style={{ fontSize: '1.1rem' }}>Find thoughtful and memorable gifts that make
                every occasion truly unforgettable.</p>
            </div>
          </div>
          <div className='col-md-12' style={{ textAlign: 'center', padding: '10px' }}>
            <span style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>Discover A​ll</strong></span>
          </div>
        </div>


        <div className='row card-section'>
          <div className='col-md-12'>
            <div style={{ textAlign: 'center' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>
                Your Journey to Thoughtful Gifting Starts Here</div>
            </div>
          </div>
          <div className='overflow-sec'>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Brows</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-001.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Select</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-002.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Pe​rsonalize</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/hp-step-m.jpg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Pay</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-004.PNG' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
            <div className='col-md-4 overflow-m' style={{ textAlign: 'center', padding: '20px', width: '20%' }}>
              <div>
                <h2 className='font-en-heading' style={{ padding: '10px', fontSize: '1rem' }}><strong>Track</strong></h2>
                <div style={{ position: 'relative' }}>
                  <img src='/mobile-frame.png' style={{ position: 'absolute', width: '100%', height: '100%', right: 0, left: 0 }} />
                  <img src='/jovia-005.jpeg' style={{ width: '100%', borderRadius: '20px', padding: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />

        <div className='row card-section' style={{ paddingBottom: '0px' }}>
          <div className='col-md-6'>
            <div style={{ textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>Become a Partner</div>

              <p className='font-en-text' style={{ fontSize: '1.4rem' }}>Join JOVIA and offer your customers the
                opportunity to create unforgettable
                memories. As a JOVIA partner, your
                exceptional services will shine alongside
                a curated collection of unique
                experiences, connecting your brand with
                a discerning audience. Together, let’s
                redefine the art of gifting.</p>
              <br />
              <div className='hp-button'>
                <div className='col-md-12' style={{ textAlign: 'start', padding: '10px' }}>
                  <Link className="link-to" to="/partner" style={{ background: '#eee', padding: '10px 20px 10px 20px', borderRadius: '100px', fontSize: '1rem' }}><strong>Become a Partner</strong></Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center' }}>
            <img src='/jovia-055.png' style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      <br /><br />
    </div>

  );
}

export default EN;
