import React from 'react';
import { Link } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'

import { useSelector } from "react-redux";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  const { t } = useTranslation()
  const { languageCode } = useSelector((state) => state.auth);


  const thisFontText = languageCode === 'ar' ? '"JannaLT", sans-serif' : '"AvenirLight", sans-serif'

  return (
    <div className="Footer-page" style={{ position: 'absolute', bottom: '0px', width: '100%', height: '0rem' }}>

      <div className="Footer-menu-card1">
        <div className="container">


          <div className="Footer-menu-card">
            <div className='row'>
              <div className='col-md-3'>
                <div className="Footer-menu-item Footer-menu-bottom" style={{ display: 'block', width: 'auto', textAlign: 'center' }}>
                  <img src='/jovia-eng+arb-white.png' style={{ width: '150px' }} />
                </div>
              </div>
              <div className='col-md-9'>
                <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'flex-end' }}>
                  <div className="Footer-menu-SubItem">
                    <Link to="./" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('Home')}
                    </Link>
                  </div>
                  <div className="Footer-menu-SubItem">
                    <Link to="/partner" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('become_a_partner')}
                    </Link>
                  </div>
                  <div className="Footer-menu-SubItem">
                    <Link to="/privacy-policy" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('Privacy_Policy')}
                    </Link>
                  </div>
                  <div className="Footer-menu-SubItem">
                    <Link to="/terms-and-conditions" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('Terms_and_Conditions')}
                    </Link>
                  </div>
                  <div className="Footer-menu-SubItem">
                    <Link to="/requests" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('Requests')}
                    </Link>
                  </div>
                  <div className="Footer-menu-SubItem">
                    <Link to="/contact-us" style={{ fontSize: '1rem', textDecoration: 'none', fontFamily: thisFontText }}>
                      {t('Contact_Us')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="Footer-menu-card">
            <div className='row'>
              <div className='col-md-3'>
                <div className="Footer-menu-item Footer-menu-bottom" style={{ display: 'block', width: 'auto', textAlign: 'center' }}>
                  <div style={{ fontSize: '1.3rem', fontWeight: 700, textTransform: 'uppercase' }}>{t('connect_with_us')}</div>
                  <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'center' }}>
                    <div className="Footer-menu-SubItem" style={{ padding: 0 }}>

                      <a href="https://www.youtube.com/@JOVIAGifts" target='_blank'>
                        <YouTubeIcon style={{ fontSize: '3.5rem', margin: '0px 7px' }} />
                      </a>
                      <a href="https://www.tiktok.com/@jovia.gifts?lang=en" target='_blank'>
                        <img src='/tiktikicon.png' style={{ width: '40px', margin: '0px 7px', padding: 0 }} />
                      </a>
                      <a href="https://www.instagram.com/jovia.gifts/reels/" target='_blank'>
                        <InstagramIcon style={{ fontSize: '2.7rem', margin: '0px 7px' }} />
                      </a>
                      <a href="https://www.facebook.com/jovia.gifts" target='_blank'>
                        <FacebookIcon style={{ fontSize: '2.7rem', margin: '0px 7px' }} />
                      </a>
                      <a href="https://wa.me/966506046626" target='_blank'>
                        <WhatsAppIcon style={{ fontSize: '2.7rem', margin: '0px 7px' }} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='fm-m' style={{ display: 'flex' }}>
                  <div className="Footer-menu-item Footer-menu-bottom" style={{ display: 'block' }}>
                    <div className="Footer-menu-SubItem mdb" style={{ fontSize: '1rem', display: 'flex', justifyContent: 'center', textTransform: 'uppercase', fontWeight: 700 }}>
                      © {new Date().getFullYear()} {t('JOVIA')} | <span style={{ margin: '0px 5px', fontFamily: thisFontText }}>{t('all_right_reserved')}</span>
                    </div>
                    <div className="Footer-menu-SubItem" style={{ fontSize: '1rem', display: 'flex', justifyContent: 'center', fontFamily: thisFontText }}>
                      شركة هداية الذكريات للتجارة - CR: 4030543003
                    </div>
                  </div>

                  <div className="Footer-menu-item Footer-menu-bottom" style={{ display: 'block', width: 'auto', textAlign: 'center', whiteSpace: 'nowrap' }}>
                    <div style={{ fontSize: '1.3rem', fontWeight: 700, fontFamily: thisFontText }}>{t('download_jovia')}</div>
                    <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'center' }}>
                      <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <a href='https://play.google.com/store/apps/details?id=com.amin.jovia' target='_blank'>
                          <div style={{ background: '#000', padding: '10px', borderRadius: '10px' }}>
                            <img src='/android-icon.svg' style={{ width: '90px', objectFit: 'contain' }} />
                          </div>
                        </a>
                        <div>&nbsp;&nbsp;</div>


                        <a href='https://apps.apple.com/ca/app/jovia-%D8%AC%D9%88%D9%81%D9%8A%D8%A7/id6504813556' target='_blank'>
                          <div style={{ background: '#000', padding: '10px', borderRadius: '10px' }}>
                            <img src='/apple-icon.svg' style={{ width: '90px', objectFit: 'contain' }} />
                          </div>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
}

export default Footer;
