import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const inApi = createApi({
  reducerPath: 'inApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Api'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    create: builder.mutation({
      query: (data) => {
        return {
          url: `/api/inquiry`,
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Api'],
    }),




  }),

})

export const { useCreateMutation } = inApi