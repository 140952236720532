import React, { useEffect, useState } from 'react';
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import Button from '@mui/joy/Button';
import { useCreateMutation } from '../../services/inquiries';

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
function EN() {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [createData, createresult] = useCreateMutation()

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [getCommercialRegistration, setCommercialRegistration] = useState(null)
  const [getVatRegistration, setVatRegistration] = useState(null)
  const [isConfirm, setConfirm] = useState(false);
  const [isAgree, setAgree] = useState(false);
  const [isYourExperience, setYourExpereince] = useState(false);

  const onConfirm = (event) => {
    setConfirm(event.target.checked);
  };
  const onAgree = (event) => {
    setAgree(event.target.checked);
  };
  const onIsYourExperisnce = (event) => {
    setYourExpereince(event.target.checked);
  };

  const checkedData = {
    Do_you_have_a_Commercial_Registration: getCommercialRegistration,
    Do_you_have_a_VAT_Registration: getVatRegistration,
    Is_Your_Experience_Seasonal: isConfirm,
    I_confirm: isAgree,
    I_Agree: isYourExperience,
    phone_Number: getPhone,
    thisSubject: 'Partner form - From'
  }


  const handleCheckboxChange = (value) => {
    setCommercialRegistration(value);
  };

  const handleVatRegistration = (value) => {
    setVatRegistration(value);
  };

  const [getSuccess, setSuccess] = useState(false)

  useEffect(() => {
    if (createresult?.isSuccess) {
      setSuccess(true)
      reset()
    }
  }, [createresult]);





  async function createInquiryFrom(data) {
    if (isValidPhoneNumber(getPhone)) {
      console.log('datadata', { ...data, ...checkedData })
      createData({ ...data, ...checkedData })
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <br /><br />
        <div className="row static-row">


          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2.5rem' }}>Partner with JOVIA</div>
              <p className='font-en-text' style={{ fontSize: '1.2rem' }}>
                Join our platform to showcase your premium experiences to a distinguished audience that values unique and thoughtful gifting. Fill out the form below, and let’s work together to create unforgettable moments for our customers.
              </p>
            </div>
          </div>


          <div className="col-md-10" style={{ padding: '0px' }}>
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0' }}>


              {getSuccess ?

                <div className="row">
                  <div className="col-md-12">
                    <h3 className='font-en-text alert alert-success' style={{ padding: '20px 0px 20px 0px', textAlign: 'center', fontSize: '1.5rem' }}>
                      {t('Form has been submitted. Thank you')}
                      <br />
                    </h3>
                  </div>
                </div>
                :

                <>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className='font-en-text' style={{ padding: '20px 0px 20px 0px', textAlign: 'start', fontSize: '1.5rem' }}>
                        {t('Partner Application Form')}
                        <br />
                      </h3>
                    </div>
                  </div>

                  <form
                    className='font-en-text'
                    onSubmit={handleSubmit(createInquiryFrom)}
                  >
                    {/* <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey=""
                        /> */}

                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>Business Information</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label >{t('Business Name ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="business_name"
                            aria-invalid={errors.business_name ? "true" : "false"}
                            {...register('business_name', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label className='pf-break'>{t('Type of Business  ')}
                            <small><i>(e.g.: Spa, Restaurant, Adventure Activity, outdoor adventure, water sports, staycation, etc.)</i></small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="type_of_buisness"
                            aria-invalid={errors.type_of_buisness ? "true" : "false"}
                            {...register('type_of_buisness', { required: true })}
                          />

                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('Business Website ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="business_website"
                            aria-invalid={errors.business_website ? "true" : "false"}
                            {...register('business_website', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label" >
                          <label >{t('Social Media Link ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="social_media"
                            aria-invalid={errors.social_media ? "true" : "false"}
                            {...register('social_media', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginBottom: '10px' }}>
                        <div className="form-group">
                          <label for="123">{t('Do you have a Commercial Registration? ')} </label>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ lineHeight: 0.6 }}>
                        <div className="form-group form-group-check">
                          <input
                            id="123yes"
                            type='checkbox'
                            checked={getCommercialRegistration === "yes"}
                            onChange={() => handleCheckboxChange("yes")}
                          />
                          <label for="123yes">{t('Yes')} </label>
                        </div>
                        <div className="form-group form-group-check">
                          <input
                            id="123no"
                            type='checkbox'
                            checked={getCommercialRegistration === "no"}
                            onChange={() => handleCheckboxChange("no")}
                          />
                          <label for="123no">{t('No')} </label>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginBottom: '10px' }}>
                        <div className="form-group">
                          <label for="1234">{t('Do you have a VAT Registration? ')} </label>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ lineHeight: 0.6 }}>
                        <div className="form-group form-group-check">
                          <input
                            id="123no2"
                            type='checkbox'
                            checked={getVatRegistration === "yes"}
                            onChange={() => handleVatRegistration("yes")}
                          />
                          <label for="123no2">{t('Yes')} </label>
                        </div>
                        <div className="form-group form-group-check">
                          <input
                            id="123yes2"
                            type='checkbox'
                            checked={getVatRegistration === "no"}
                            onChange={() => handleVatRegistration("no")}
                          />
                          <label for="123yes2">{t('No')} </label>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>Contact Details</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label >{t('Contact Person’s Full Name ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="full_name"
                            aria-invalid={errors.full_name ? "true" : "false"}
                            {...register('full_name', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('Position/Title ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="position"
                            aria-invalid={errors.position ? "true" : "false"}
                            {...register('position', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label" >
                          <label >{t('Email Address ')} </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('Phone Number')} </label>
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={'SA'}
                            value={getPhone}
                            onChange={setPhone}
                          />
                          {getPhoneError &&
                            <>
                              {getPhone && isValidPhoneNumber(getPhone) ? '' :
                                <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                              }
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label" >
                          <label >{t('WhatsApp Number (for customer booking) ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="whatsapp_number"
                            aria-invalid={errors.whatsapp_number ? "true" : "false"}
                            {...register('whatsapp_number', { required: true })}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ fontSize: '1.2rem', fontWeight: 600, marginBottom: '5px' }}>Experience and Operational Information</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group form-group-label">
                          <label>{t('Brief Description of the Experience You Offer ')}<br />
                            <small><i>(e.g., fine dining with a chef’s table experience, tandem skydiving, pottery workshops, etc.)</i></small>
                          </label>

                          <textarea
                            type="text"
                            className="form-control"
                            id="message"
                            aria-invalid={errors.message ? "true" : "false"}
                            {...register('message', { required: true })}
                          >
                          </textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('What Makes Your Experience Unique? ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="what_makes_your_experience_unique"
                            aria-invalid={errors.what_makes_your_experience_unique ? "true" : "false"}
                            {...register('what_makes_your_experience_unique', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('Location of the Experience ')} </label>
                          <select
                            id="location"
                            aria-invalid={errors.location ? "true" : "false"}
                            {...register('location', { required: true })}
                            className='form-control'>
                            <option>Select</option>
                            <option>Riyadh</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group form-group-label">
                          <label>{t('Estimated Voucher Price Range ')} </label>
                          <input
                            type="text"
                            className="form-control"
                            id="estimated_voucher_price_range"
                            aria-invalid={errors.estimated_voucher_price_range ? "true" : "false"}
                            {...register('estimated_voucher_price_range', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group form-group-check">
                          <input id="12345" type='checkbox' checked={isYourExperience} onChange={onIsYourExperisnce} />
                          <label for="12345">{t('Is Your Experience Seasonal?  ')} </label>
                        </div>
                      </div>

                    </div>


                    <div className="row">

                      <div className="col-md-12">
                        <div className="form-group form-group-label">
                          <label>
                            Additional Notes
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="additional_note"
                            aria-invalid={errors.additional_note ? "true" : "false"}
                            {...register('additional_note', { required: true })}
                          >
                          </textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group form-group-check">
                          <input id="12347" type='checkbox' checked={isConfirm} onChange={onConfirm} />
                          <label for="12347">I confirm that I am authorized to submit this application on behalf of my business.</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group form-group-check">
                          <input id="12346" type='checkbox' checked={isAgree} onChange={onAgree} />
                          <label for="12346">I agree to be contacted by JOVIA regarding this partnership.</label>
                        </div>
                      </div>

                      <br />
                      <div className="col-md-12 Header-signin-button">
                        <div className="form-group">
                          <Button
                            variant='soft'
                            size='sm'
                            disabled={createresult.isLoading}
                            style={{ width: '50%', height: '45px', marginBottom: '20px' }}
                            type="submit">
                            {createresult.isLoading ? t('loading') : t('submit')}
                          </Button>

                        </div>
                      </div>
                    </div>
                  </form>
                </>
              }

              <br /><br />
            </div>
          </div>



          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2rem' }}>Partner Onboarding Process</div>
              <br />
              <ol className='p-sec-text'>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Assessment and Evaluation</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Once we receive your application, we evaluate your business for quality and premium standards.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Approval and Contracting</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>If your business is approved, a team member will contact you to send and sign the partnership agreement and obtain all necessary legal documents.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Onboarding</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>After signing the agreement, we work closely with you to design the experience offerings. Kindly ensure quality images and videos of your service that include a human element in the photos.</span>
                </li>
                <li style={{ fontSize: '1rem', marginBottom: '10px' }}><span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Brand Dashboard Access</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Once the experiences are finalized, you will receive a username and password to access your Brand Dashboard, where you can monitor and verify vouchers and claim your payout.</span>
                </li>
              </ol>
            </div>
          </div>

          <br /><br />


          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <div className='font-en-heading' style={{ fontSize: '2rem' }}>FAQ for Partner Onboarding</div>
              <br />
              <div className='p-sec-text'>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>
                    What is JOVIA?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>JOVIA is a gifting platform that specializes in premium and unique experience vouchers, offering customers thoughtful alternatives to traditional gifts.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Why should I partner with JOVIA?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>By partnering with JOVIA, you’ll gain access to a curated audience seeking premium, unique experiences. You’ll also benefit from enhanced visibility, seamless voucher management, and a reliable payout system.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Who can become a partner?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>We partner with businesses offering premium experiences such as dining, spa treatments, adventure activities, workshops, and other unique services. To qualify, your business must meet our quality standards and provide a memorable experience for customers.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Is there a cost to join JOVIA?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>No, there is no upfront cost to join JOVIA. We operate on a commission-based model, so you earn revenue as customers purchase vouchers for your experiences.</span>
                </div>


                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>How do I apply to become a partner?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Fill out the form on our “Become a Partner” page, providing details about your business, the experiences you offer, and your contact information. Be sure to include your commercial and VAT registration status.</span>
                </div>

                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>What happens after I submit my application?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Our team will assess your application and evaluate your brand based on quality and premium standards. If approved, you’ll hear back from us within 3–5 business days.</span>
                </div>

                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>What are the next steps after my application is approved?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Once approved, a team member will contact you to finalize the agreement by sending the partnership contract. We will also collect all required legal documents to complete the process.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>What is included in the onboarding process?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>During onboarding, we’ll work with you to design the experience packages that will be listed on JOVIA. You’ll need to provide:</span>
                  <ul>
                    <li>High-quality images and videos of your services, preferably showcasing the human element.</li>
                    <li>Pricing and package details.</li>
                    <li>Any other content that enhances the listing.</li>

                  </ul>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>How long does the onboarding process take?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>The onboarding process typically takes 1–2 weeks, depending on how quickly we receive the required materials and finalize the agreement.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>How do I manage my vouchers and bookings?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Once your experiences are live, you’ll receive access to your Brand Dashboard, where you can:</span>
                  <ul>
                    <li>Verify and confirm customer vouchers.</li>
                    <li>View your live vouchers (you can request any edits by contacting brands@jovia.gifts).</li>
                    <li>Monitor and request your payouts.</li>
                    <li>Track your overall sales performance.</li>
                  </ul>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>How will I receive payments?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Payouts are processed directly through your Brand Dashboard once a month. You must submit one invoice for all the selected vouchers you wish to be paid out. You will receive your payments within 3–4 business days from your invoice submission and view a VAT invoice for the commission. You’ll be able to track all completed transactions and claim your earnings with ease.</span>
                </div>


                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Can I update or change my experience packages?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Yes, you can update or modify your offerings at any time. Simply contact us at brands@jovia.gifts, and we’ll help you make the necessary changes.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>What kind of images and videos should I provide?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>We recommend using high-quality images and videos that highlight your services in action. Include the human element to make the experiences relatable and appealing to our customers.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Do I need to offer exclusive packages for JOVIA?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>Exclusive packages are not mandatory but are highly encouraged. Offering something unique to JOVIA customers can increase your visibility and appeal.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>How does JOVIA ensure the quality of experiences?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>We carefully evaluate each partner to ensure they meet our premium standards. We also monitor customer feedback to maintain quality across all experiences listed on JOVIA.</span>
                </div>
                <div style={{ fontSize: '1rem', marginBottom: '20px' }}>
                  <span className='font-en-heading' style={{ fontWeight: 500, fontSize: '1.2rem' }}>Who can I contact for help during the process?</span><br />
                  <span className='font-en-text' style={{ fontSize: '1.2rem' }}>If you have any questions or need assistance, please contact us at brands@jovia.gifts.</span>
                </div>
              </div>
            </div>
          </div>

          <br /><br />



        </div>
      </div>
      <br /><br />
      <br />
    </div>
  );
}

export default EN;

