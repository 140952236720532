import React, { useEffect, useState } from 'react';
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import Button from '@mui/joy/Button';
import { useSelector } from "react-redux";
import { useCreateMutation } from '../../services/inquiries';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
function EN() {
  const { languageCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [createData, createresult] = useCreateMutation()

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)


  const checkedData = {
    phone_Number: getPhone,
    thisSubject: 'Request form - From'
  }


  async function createInquiryFrom(data) {
    if (isValidPhoneNumber(getPhone)) {
      console.log('datadata', { ...data, ...checkedData })
      createData({ ...data, ...checkedData })
    } else {
      setPhoneError(true)
    }
  }

  const [getSuccess, setSuccess] = useState(false)

  useEffect(() => {
    if (createresult?.isSuccess) {
      setSuccess(true)
      reset()
    }
  }, [createresult]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <div className='container'>
        <br /><br />
        <div className="row static-row">






          <div className="col-md-10">
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0', textAlign: 'start' }}>
              <p className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'} style={{ fontSize: '1.4rem' }}>
                {languageCode === 'ar' ? 'أخبرنا ما هي التجارب التي ترغب في أن نضيفها على منصتنا' :
                  "Tell us what experiences you'd like us to add on our platform"

                }
              </p>
            </div>
          </div>




          <div className="col-md-10" style={{ padding: '0px' }}>
            <div className="static-container-card" style={{ padding: '0px', background: '#fff0' }}>



              {getSuccess ?

                <div className="row">
                  <div className="col-md-12">
                    <h3 className='font-en-text alert alert-success' style={{ padding: '20px 0px 20px 0px', textAlign: 'center', fontSize: '1.5rem' }}>
                      {t('Form has been submitted. Thank you')}
                      <br />
                    </h3>
                  </div>
                </div>
                :



                <form
                  onSubmit={handleSubmit(createInquiryFrom)}
                >
                  {/* <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey=""
                        /> */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-group-label">
                        <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('first_name')} </label>
                        <input
                          type="text"
                          className="form-control"
                          id="full_name"
                          aria-invalid={errors.full_name ? "true" : "false"}
                          {...register('full_name', { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-group-label">
                        <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('last_name')} </label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          aria-invalid={errors.last_name ? "true" : "false"}
                          {...register('last_name', { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-group-label" >
                        <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('email')} </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          aria-invalid={errors.email ? "true" : "false"}
                          {...register('email', { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-group-label">
                        <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('phone')} </label>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry={'SA'}
                          value={getPhone}
                          onChange={setPhone}
                        />
                        {getPhoneError &&
                          <>
                            {getPhone && isValidPhoneNumber(getPhone) ? '' :
                              <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                            }
                          </>
                        }
                      </div>
                    </div>

                  </div>


                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group form-group-label">
                        <label className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>
                          {languageCode === 'ar' ?
                            'أخبرنا ما هي التجارب التي ترغب في أن نضيفها على منصتنا' :
                            'Share with us what services and experiences you would like to see on our platform'
                          }
                        </label>

                        <textarea
                          type="text"
                          className="form-control"
                          id="message"
                          aria-invalid={errors.message ? "true" : "false"}
                          {...register('message', { required: true })}>
                        </textarea>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-6 Header-signin-button">
                      <div className="form-group">
                        <Button
                          variant='soft'
                          size='sm'
                          disabled={createresult.isLoading}
                          style={{ width: '50%', height: '45px', marginBottom: '20px' }}
                          type="submit">
                          {createresult.isLoading ?
                            <span className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('loading')}</span>
                            :
                            <span className={languageCode === 'ar' ? 'font-ar-text' : 'font-en-text'}>{t('submit')}</span>
                          }

                        </Button>

                      </div>
                    </div>
                  </div>
                </form>
              }

              <br /><br />
            </div>
          </div>

        </div>
      </div>
      <br /><br />
      <br />
    </div>
  );
}

export default EN;

